<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" order-md="last">
        <v-sheet class="pa-4" min-height="100" rounded="lg" outlined>
          <v-row>
            <v-col
              v-for="question in questions"
              :key="question.position"
              md="3"
            >
              <v-btn
                :color="
                  question.position === questionNumber
                    ? 'success'
                    : question.isAnswered
                    ? 'primary'
                    : 'default'
                "
                @click="loadQuestion(question.questionId, question.position)"
              >
                {{ question.position }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="8" order-md="first">
        <v-sheet min-height="200" rounded="lg" outlined>
          <v-row>
            <v-col cols="9"
              ><v-card-title
                >{{ subtest.test_name }} - {{ subtest.subject_name }} •
                {{ this.questionPosition }}</v-card-title
              ></v-col
            >
            <v-spacer />
            <v-col cols="3"
              ><v-card-title
                :class="`float-right ${
                  countdown === 'Test is over' ? 'red--text' : 'black--text'
                }`"
                >{{ countdown }}</v-card-title
              ></v-col
            >
          </v-row>
          <v-divider />
          <div class="pa-4">
            <span v-html="question.question"></span>
            <v-divider class="mt-4" />
            <v-radio-group v-model="selectedAnswer">
              <v-radio
                v-for="answer in question.answers"
                :key="answer.id"
                :value="answer.id"
                class="ma-2"
              >
                <template v-slot:label>
                  <div style="width: 100%">
                    <div v-html="answer.answer"></div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <v-divider />
          <v-card-actions>
            <v-btn
              v-if="questionNumber > 1"
              @click="
                loadQuestion(
                  findQuestionIdByPosition(questionNumber - 1),
                  questionNumber - 1
                )
              "
              color="primary"
            >
              <v-icon left>mdi-menu-left</v-icon>
              Previous Question
            </v-btn>
            <v-spacer />
            <v-btn
              @click="
                questionNumber === questions.length
                  ? confirmEndSubtestDialog()
                  : loadQuestion(
                      findQuestionIdByPosition(questionNumber + 1),
                      questionNumber + 1
                    )
              "
              :color="questionNumber === questions.length ? 'error' : 'primary'"
            >
              {{
                questionNumber === questions.length
                  ? "End This Test"
                  : "Next Question"
              }}
              <v-icon v-if="questionNumber !== questions.length" right
                >mdi-menu-right</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="endSubtestDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> End This test? </v-card-title>

        <v-card-text>
          There is {{ countdown }} left to continue {{ unansweredQuestion }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="success darken-1"
            @click="endSubtest"
            :loading="endSubtestLoading"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="testEndedDialog"
      @click:outside="closeTestEndedDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline"> Test ended </v-card-title>

        <v-card-text>
          Test is ended and answers automatically recorded
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success darken-1" @click="closeTestEndedDialog" text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ongoingTestDialog" max-width="500">
      <v-card>
        <v-card-title
          >{{ test.name }} <br />
          {{ test.testEnd | dynamicTimeDifference }}
          left</v-card-title
        >
        <v-divider />
        <div class="pa-4">
          <v-skeleton-loader
            v-if="ongoingSubtestLoading"
            type="paragraph"
            :loading="true"
          />
          <div v-if="!ongoingSubtestLoading">
            <div
              v-for="(subtestList, subtestType) in subtests"
              :key="subtestType"
            >
              <span class="font-weight-medium">{{ subtestType }}</span>
              <div class="mt-2">
                <div
                  v-for="subtest in subtestList"
                  class="ml-4 mb-1"
                  :key="subtest.id"
                >
                  <span
                    @click="startSubtestDialog(subtest)"
                    :class="`${testAvailabilityColor(
                      subtest.status
                    )}--text pointer-cursor`"
                    >{{ subtest.position }}. {{ subtest.name }}</span
                  >
                  <div class="text--disabled">
                    {{ subtest.duration }} minutes
                    <span v-if="subtest.score !== -1"
                      >• scored
                      <span class="success--text">{{
                        subtest.score
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!ongoingSubtestLoading"
          class="d-flex flex-column align-center pa-2"
        >
          <v-btn @click="openEndTestDialog" color="error">
            End All Tests
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="startSubtestConfirmationDialog"
      :persistent="startSubtestLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Confirm Start Test</v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>
            By clicking <b>START TEST</b> the test and the timer will be started
          </p>
          <p>
            Please note that the answer will be recorded only if
            <b>Next Question Button</b>, <b>Previous Question Button</b>, or
            <b>Question Number Button</b> clicked
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="startSubtestLoading"
            @click="startSubtest"
            type="submit"
            color="green"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="endTestDialog"
      :persistent="endTestLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Confirm End Test</v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>
            By clicking <b>CONFIRM</b> the test and the subtests will be ended
            and calculated
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="endTestLoading"
            @click="endTest"
            type="submit"
            color="green"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="css" scoped>
@import "~quill/dist/quill.snow.css";
.pointer-cursor:hover {
  cursor: pointer;
}
</style>

<script>
import {
  getDate,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  lastDayOfMonth
} from "date-fns";
import katex from "katex";
import { mapActions } from "vuex";
import SubtestService from "@/services/subtest";
import TestService from "@/services/test";
import "katex/dist/katex.min.css";

export default {
  name: "Test.Test",
  data() {
    return {
      test: {
        name: "",
        testEnd: ""
      },
      runningTest: {
        testEnd: ""
      },
      countdown: "",
      questions: [],
      question: {
        question: ""
      },
      questionNumber: 1,
      selectedAnswer: undefined,
      originalSelectedAnswer: "",
      endSubtestDialog: false,
      endSubtestLoading: false,
      testEndedDialog: false,
      subtest: {
        name: "",
        subject_name: ""
      },
      subtests: [],
      runningTestId: "",
      ongoingTestDialog: false,
      ongoingSubtestLoading: false,
      startSubtestConfirmationDialog: false,
      endTestDialog: false,
      endTestLoading: false,
      startSubtestLoading: false
    };
  },
  filters: {
    dynamicTimeDifference(dateTime) {
      dateTime = new Date(dateTime);
      if (differenceInSeconds(dateTime, new Date()) > 60) {
        if (differenceInMinutes(dateTime, new Date()) > 60) {
          if (differenceInHours(dateTime, new Date()) > 60) {
            if (
              differenceInDays(dateTime, new Date()) >
              getDate(lastDayOfMonth(dateTime))
            ) {
              if (differenceInMonths(dateTime, new Date() > 12)) {
                return `${differenceInYears(dateTime, new Date())} months`;
              }
              return `${differenceInMonths(dateTime, new Date())} months`;
            }
            return `${differenceInDays(dateTime, new Date())} days`;
          }
          return `${differenceInHours(dateTime, new Date())} hours`;
        }
        return `${differenceInMinutes(dateTime, new Date())} minutes`;
      }
      return `${differenceInSeconds(dateTime, new Date())} seconds`;
    }
  },
  computed: {
    dataId() {
      return this.$route.params.id;
    },
    questionPosition() {
      return `${this.questionNumber}/${this.questions.length}`;
    },
    unansweredQuestionCount() {
      return this.questions.filter(({ isAnswered }) => !isAnswered).length;
    },
    unansweredQuestionPlural() {
      return this.unansweredQuestionCount > 1 ? "s" : "";
    },
    unansweredQuestion() {
      if (this.unansweredQuestionCount !== 0) {
        return ` and ${this.unansweredQuestionCount} unanswered question${this.unansweredQuestionPlural}`;
      }

      return "";
    }
  },
  mounted() {
    window.katex = katex;
  },
  async created() {
    this.setNavbarTitle("Subtest");
    await Promise.allSettled([
      this.findRunningSubtestById(this.dataId),
      this.findTestInformation(this.dataId),
      this.findRunningSubtest(),
      this.findRunningSubtestQuestions().then(() =>
        this.loadQuestion(
          this.findQuestionIdByPosition(this.questionNumber),
          this.questionNumber
        )
      )
    ]);
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async findRunningSubtest() {
      const fetchRunningSubest = await SubtestService.findRunningSubtest(
        this.dataId
      );
      const subtest = fetchRunningSubest.data.data;
      this.subtest = subtest;
      this.countdownTestEnd(subtest.test_end);
    },
    async findRunningSubtestQuestions() {
      const questions = await SubtestService.findRunningSubtestQuestions(
        this.dataId
      );
      this.questions = questions.data.data;
    },
    countdownTestEnd(datetime) {
      const end = new Date(datetime);

      const SECOND = 1000;
      const MINUTE = SECOND * 60;
      const HOUR = MINUTE * 60;
      const DAY = HOUR * 24;
      const _this = this;
      let timer;

      function showRemaining() {
        if (_this.countdown === "Test is over") {
          _this.countdown = "Test is over";
          clearInterval(timer);
          return;
        }

        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          _this.testEndedDialog = true;
          _this.countdown = "Test is over";
          clearInterval(timer);
          return;
        }
        const hours = Math.floor((distance % DAY) / HOUR);
        const minutes = Math.floor((distance % HOUR) / MINUTE);
        const seconds = Math.floor((distance % MINUTE) / SECOND);

        _this.countdown = `${("00" + hours).slice(-2)}:${("00" + minutes).slice(
          -2
        )}:${("00" + seconds).slice(-2)}`;
      }

      timer = setInterval(showRemaining, 1000);
    },
    async loadQuestion(questionId, position) {
      if (
        this.selectedAnswer !== undefined &&
        this.selectedAnswer !== this.originalSelectedAnswer
      ) {
        await this.answerQuestion();
      }

      this.questionNumber = position;
      this.question = { question: "" };
      this.selectedAnswer = undefined;
      const question = await SubtestService.findQuestion(questionId, {
        running_subtest_id: this.dataId
      });
      this.question = question.data.data.question;
      const selectedAnswer =
        question.data.data.testAnswer?.answerId || undefined;
      this.selectedAnswer = selectedAnswer;
      this.originalSelectedAnswer = selectedAnswer;
    },
    findQuestionIdByPosition(position) {
      return this.questions.find(question => question.position === position)
        .questionId;
    },
    async confirmEndSubtestDialog() {
      if (
        this.selectedAnswer !== undefined &&
        this.selectedAnswer !== this.originalSelectedAnswer
      ) {
        await this.answerQuestion();
      }
      this.endSubtestDialog = true;
    },
    async answerQuestion() {
      this.questions.find(
        question => question.position === this.questionNumber
      ).isAnswered = true;
      await SubtestService.answerQuestion(this.dataId, {
        question_id: this.question.id,
        answer_id: this.selectedAnswer
      });
    },
    async endSubtest() {
      this.endSubtestLoading = true;
      try {
        await SubtestService.endTest(this.dataId);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.ongoingTestDialog = true;
        this.ongoingSubtestLoading = true;
        const subtests = await TestService.runningSubtests(this.runningTestId);
        this.subtests = subtests.data.data;
        this.ongoingSubtestLoading = false;
        this.countdown = "Test is over";
      } catch (error) {
        this.endSubtestLoading = false;
      }
    },
    closeTestEndedDialog() {
      this.$router.push({ name: "Dashboard" });
    },
    async findRunningSubtestById(runningSubtestId) {
      const runningSubtest = await SubtestService.runningSubtestById(
        runningSubtestId
      );
      this.runningTestId = runningSubtest.data.data.runningTestId;
    },
    startSubtestDialog(subtest) {
      if (subtest.status === "ongoing") {
        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.id }
        });
      } else if (subtest.status === "started") {
        this.startSubtestConfirmationDialog = true;
        this.selectedSubtest = subtest;
      }
    },
    async endTest() {
      try {
        this.endTestLoading = true;
        await TestService.endTest(this.runningTestId);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.endTestLoading = false;
        this.endTestDialog = false;
        this.ongoingTestDialog = false;
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        this.endTestLoading = false;
      }
    },
    openEndTestDialog() {
      this.endTestDialog = true;
    },
    async findTestInformation(runningSubtestId) {
      const test = await SubtestService.testInformation(runningSubtestId);
      this.test = test.data.data;
    },
    async startSubtest() {
      try {
        this.startSubtestLoading = true;
        const subtest = await TestService.startSubtest({
          running_test_id: this.runningTestId,
          subtest_id: this.selectedSubtest.id,
          test_id: this.selectedSubtest.test_id
        });
        this.startSubtestLoading = false;
        this.startSubtestConfirmationDialog = false;
        this.ongoingTestDialog = false;

        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.data.data.id }
        });
      } catch (error) {
        this.startSubtestLoading = false;
      }
    },
    testAvailabilityColor(status) {
      switch (status) {
        case "not yet started":
          return "default";
        case "started":
          return "primary";
        case "ended":
          return "error";
        case "ongoing":
          return "warning";
        case "done":
          return "success";
      }
    }
  }
};
</script>
